const InvitationFields = `
  id
  account {
    id
    householdName
    householdId
    teamName
    teamId
    owners {
      id
      firstName
      lastName
      avatar {
        url
      }
      avatarColor
    }
  }
  claimToken
  claimUrl
  claimStartedAt
  claimedAt
  claimedUserId
  createdAt
  currentUserIsClaiming
  declinedAt
  deletedAt
  email
  existingUserId
  expiredAt
  expiresOn
  isNewToHousehold
  firstName
  fullClaimedAt
  invitationType
  lastName
  phoneNumber
  sender {
    id
    firstName
    lastName
  }
  status
`;

export const userFields = `
  id
  tpId
  zetaOnboardingStatus
  fullyClosed
  accounts {
    id
    closeRequestedAt
    closedAt
    possibleCloseDate
    initiallyFundedAt
    mask
  }
  accountApplications {
    id
    status
    accountType
    manualReviewMailersSentAt
    personApplications {
      id
      kycStatus
      awaitingDocumentation
      documentationConversationId
      applicant {
        ... on User {
          id
          firstName
          lastName
          email
        }
        ... on Companion {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
  additionalOwnerInvitations {
    ${InvitationFields}
  }
  features
  firstName
  globalId
  lastName
  legalFirstName
  lockedAt
  phoneNumber
  email
  onboardingStatus
  partner {
    id
    tpId
    firstName
    lastName
    onboardingStatus
    phoneNumber
    email
    seenFtes
    profile {
      id
      city
      dateOfBirth
      mailingCity
      mailingState
      mailingStreetAddress1
      mailingStreetAddress2
      mailingZipcode
      state
      streetAddress1
      streetAddress2
      zipcode
    }
  }
  pendingPartnerFirstName
  pendingPartnerLastName
  pendingRelationshipStatus
  pendingPartnerDateOfBirth
  preferences
  invitations {
    ${InvitationFields}
  }
  profile {
    id
    anniversary
    city
    dateOfBirth
    individualAnnualIncome
    occupation
    payFrequency
    sourceOfIncome
    state
    streetAddress1
    streetAddress2
    zipcode
    mailingState
    mailingStreetAddress1
    mailingStreetAddress2
    mailingZipcode
    mailingCity
  }
  relationships {
    id
    status
  }
  seenFtes
  teamName
  userType
  universalId
`;

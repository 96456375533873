import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import { Button } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { H1, Paragraph, ExternalLink } from 'shared/typography';
import colors from 'app/theme/foundations/colors';
import { useStoreState, useStoreActions } from 'easy-peasy';

const NeedMoreTime = () => {
  useAnalytics({ name: 'manual-review' });
  const navigate = useNavigate();
  const { getUser } = useStoreActions(state => state.auth);

  const { user } = useStoreState(state => state.auth);
  const accountApplication = user?.accountApplications?.[0] || {};

  const goToFinal = () => navigate('/auth/final');

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg={colors.primary}
      justify="center"
      align="center"
    >
      <Flex
        direction="column"
        position="fixed"
        h="auto"
        w={420}
        bg="white"
        align="center"
        px={8}
        py={8}
        borderRadius={8}
      >
        <Box>
          <H1 textAlign="left" fontWeight="semibold" fontSize={['2xl', '3xl']}>
            Documents required
          </H1>
          <Box h={2} />
          <Paragraph color="#787878">
            To complete your application, submit the required documents through the app.
            Please DO NOT email us sensitive documents.
          </Paragraph>
          <Box h={10} />
          {accountApplication?.personApplications?.map(pa => {
            const { applicant = {}, awaitingDocumentation, kycStatus } = pa;
            const inReview = kycStatus !== 'approved' && awaitingDocumentation;

            return (
              <Flex
                key={`person-app${pa.id}`}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderTopWidth: 1,
                  borderColor: '#E5E5E5',
                }}
                py={4}
              >
                <Flex
                  style={{
                    width: 48,
                    height: 48,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {inReview ? (
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.3337 12.3359H17.0003C15.717 12.3359 14.667 13.3859 14.667 14.6693V33.3359C14.667 34.6193 15.7053 35.6693 16.9887 35.6693H31.0003C32.2837 35.6693 33.3337 34.6193 33.3337 33.3359V19.3359L26.3337 12.3359ZM31.0003 33.3359H17.0003V14.6693H25.167V20.5026H31.0003V33.3359Z"
                        fill="#EE5C3D"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.22915 14.7412C8.85528 15.1147 8.24946 15.1146 7.87577 14.7409L5.23521 12.1007C4.86233 11.7279 4.25773 11.7272 3.88353 12.0987C3.50746 12.4721 3.50601 13.0803 3.88076 13.455L7.87545 17.4491C8.24926 17.8229 8.85533 17.8229 9.22915 17.4491L19.3638 7.31587C19.7366 6.94313 19.7366 6.33881 19.3638 5.96608C18.9911 5.59346 18.387 5.59332 18.0141 5.96576L9.22915 14.7412Z"
                        fill="#3EB579"
                      />
                    </svg>
                  )}
                </Flex>
                <Box ml={4}>
                  <Paragraph fontSize="16px" fontWeight="semibold">
                    {applicant?.firstName} {applicant?.lastName}
                  </Paragraph>
                  <Box height={1} />
                  <Paragraph fontSize="14px" color="#787878">
                    {inReview ? 'Action required' : 'No action required'}
                  </Paragraph>
                  {inReview ? (
                    <Paragraph m color="#787878">
                      Check {applicant.email}
                    </Paragraph>
                  ) : null}
                </Box>
              </Flex>
            );
          })}
        </Box>
        <Box h={40} />
        <Flex justify="center">
          <Button w={68} onClick={goToFinal}>
            Upload in the app
          </Button>
          <br />
        </Flex>
        <Box h={6} />
        <Flex justify="center">
          <ExternalLink
            href="mailto:concierge@askzeta.com"
            style={{ textDecoration: 'none' }}
          >
            <Paragraph color={colors.greenPea} fontWeight="semibold">
              Get help
            </Paragraph>
          </ExternalLink>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NeedMoreTime;
